@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/common" as c;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  height: v.$header_height;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .header_l {
    z-index: 11;
    position: fixed;
    top: v.$body_pd;
    left: v.$body_pd;
    img {
      width: clamp(48px, f.clamp_prf(82px), 82px);
      height: auto;
    }
    @include c.tab {
      top: 14px;
      left: 14px;
    }
  }
  .header_r {
    position: fixed;
    top: v.$body_pd;
    right: v.$body_pd;
  }
  .share_link {
    display: flex;
    align-items: center;
    gap: 20px;
    .share {
      line-height: 0;
      img {
        width: 45px;
        height: 8px;
      }
    }
    .tw {
      background-color: v.$letter_primary;
      display: grid;
      place-items: center;
      width: 36px;
      height: 36px;
      transition: 0.15s;
      img {
        width: 20px;
        height: 16px;
      }
    }
    &:hover {
      .tw {
        background-color: v.$tw_blue;
      }
    }
  }
  .share_link_pc {
    @include c.tab {
      display: none;
    }
    @include c.low_height {
      display: none;
    }
  }
  .gnav_pc {
    position: fixed;
    top: 55%;
    left: 40px;
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: v.$fw_medium_500;
    letter-spacing: 0.215em;
    gap: clamp(20px, 4vh, 46px);
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      pointer-events: none;
      a {
        pointer-events: auto;
        transition: 1.15s;
        position: relative;
        &:before {
          transition: 0.5s;
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: transparent;
          transition: all 0.3s;
          transform: scale(0, 1);
          transform-origin: left top;
        }
        &.active {
          &:before {
            transform: scale(1, 1);
            background-color: v.$letter_primary;
          }
        }
        @include c.hover_device {
          &:hover {
            &:before {
              transform: scale(1, 1);
              background-color: v.$letter_primary;
            }
          }
        }
      }
    }
    @include c.tab {
      display: none;
    }
    @include c.low_height {
      display: none;
    }
  }

  #sp_menu {
    display: none;
    height: calc(f.svh(100));
    width: 100%;
    transition: all 0.2s ease-in-out;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 15;
    @include c.tab {
      display: block;
    }
    @include c.low_height {
      display: block;
    }
    &.active {
      @include c.tab {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
      @include c.low_height {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
    .menu_inner {
      visibility: inherit;
      opacity: inherit;
      width: clamp(216px, 100%, 700px);
      padding: clamp(60px, 10vh, 100px) 40px 0 clamp(40px, 10vw, 80px);
      margin: 0 auto;
    }
    .sp_menu_logo {
      display: block;
      margin-bottom: clamp(40px, 6vh, 60px);
      width: clamp(200px, 64vw, 500px);
      height: auto;
    }
    .gnav_sp {
      margin-bottom: clamp(40px, 20vh, 60px);
      display: flex;
      flex-direction: column;
      gap: clamp(30px, 5vh, 40px);
      .link_wrapper {
        a {
          display: flex;
          font-weight: v.$fw_semibold_600;
          font-size: 16px;
          gap: 16px;
          line-height: 1;
          color: v.$letter_primary;
        }
        .name {
          display: block;
          flex-grow: 0;
          line-height: 1;
          padding-top: 4px;
          z-index: -1;
        }
      }
    }
    .share_link_sp {
      margin-bottom: 20px;
    }
    .copyright {
      font-size: 11px;
      line-height: 1.8;
    }
  }

  #header_toggle {
    // タップできる範囲の設定
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 100vh;
    cursor: pointer;
    z-index: 20;
    display: none;
    background-color: transparent;
    pointer-events: auto;
    @include c.tab {
      display: block;
    }
    @include c.low_height {
      display: block;
    }
    span {
      display: block;
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: v.$letter_primary;
      transition: 0.3s ease-in-out;
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
    }
  }
  // 線の位置はここで調整
  $header_toggle_top: 16px;
  $header_toggle_top_offset: 8px;
  $header_toggle_right: 14px;
  #header_toggle span:nth-child(1) {
    top: $header_toggle_top;
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(2) {
    top: calc($header_toggle_top + $header_toggle_top_offset);
    right: $header_toggle_right;
  }
  #header_toggle span:nth-child(3) {
    top: calc($header_toggle_top + ($header_toggle_top_offset * 2));
    right: $header_toggle_right;
  }
  #header_toggle.active span:nth-child(1) {
    width: 30px;
    transform: translate(2px, 8px) rotate(225deg);
  }
  #header_toggle.active span:nth-child(2) {
    transform: translate(-50px, 0) scale(0);
  }
  #header_toggle.active span:nth-child(3) {
    width: 30px;
    transform: translate(2px, -8px) rotate(-225deg);
  }
}

/*=======================================
  main
=======================================*/
.main {
  font-family: v.$noto_yakuhan;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  #first_view {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: -1;
    .header_c {
      position: absolute;
      top: v.$body_pd;
      left: 50%;
      transform: translate(-50%, 0);
      img {
        width: clamp(110px, f.clamp_prf(305px), 305px);
        height: auto;
      }
      @include c.tab {
        top: 18px;
      }
    }
    .character {
      position: fixed;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: 20vh;
        left: 50%;
        transform: translate(-50%, 0);
        width: 55vmax;
        height: auto;
        @include c.tab {
          top: 30vh;
          width: 55vmax;
        }
      }
      &.anxiety {
        opacity: 0;
        transition: 0.4s;
        &.active {
          opacity: 1;
        }
      }
    }
    .character_bg_props {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      animation: props_animation 32s ease-in-out infinite;
      @keyframes props_animation {
        0% {
          transform: translate3d(0, 0, 0);
        }
        25% {
          transform: translate3d(clamp(20px, 1vw, 30px), clamp(20px, 1vw, 30px), 0);
        }
        50% {
          transform: translate3d(0, clamp(40px, 2vw, 60px), 0);
        }
        75% {
          transform: translate3d(clamp(-30px, -1vw, -20px), clamp(20px, 1vw, 30px), 0);
        }
        100% {
          transform: translate3d(0, 0, 0);
        }
      }
      .prop {
        position: absolute;
        height: auto;
        transition: 3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.cosme {
          top: 19vh;
          left: 63vw;
          width: 12vw;
        }
        &.phone {
          top: 46vh;
          left: 21vw;
          width: 12vw;
        }
        &.camera {
          top: 78vh;
          left: 28vw;
          width: 10vw;
        }
        &.mic {
          top: 7vh;
          left: 25vw;
          width: 14vw;
        }
        &.tablet {
          top: 54vh;
          left: 55vw;
          width: 24vw;
        }
        @include c.tab {
          &.cosme {
            top: 30vh;
            left: 53vw;
            width: 19vw;
          }
          &.phone {
            top: 46vh;
            left: 25vw;
            width: 20vw;
          }
          &.camera {
            top: 82vh;
            left: 8vw;
            width: 13vw;
          }
          &.mic {
            top: 17vh;
            left: 28vw;
            width: 18vw;
          }
          &.tablet {
            top: 70vh;
            left: 70vw;
            width: 24vw;
          }
        }
        @include c.sp {
          &.cosme {
            top: 51vh;
            left: 64vw;
            width: 29vw;
          }
          &.phone {
            top: 57vh;
            left: 4vw;
            width: 24vw;
          }
          &.camera {
            top: 75vh;
            left: 1vw;
            width: 22vw;
          }
          &.mic {
            top: 25vh;
            left: 19vw;
            width: 25vw;
          }
          &.tablet {
            top: 62vh;
            left: 66vw;
            width: 36vw;
          }
        }
      }
    }
    .character_bg_papers {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      transform: translateY(0px);
      transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
      pointer-events: none;
      .paper {
        position: absolute;
        height: auto;
        &.n_01 {
          top: 25vh;
          left: -7vw;
          width: 13vw;
        }
        &.n_02 {
          top: 85vh;
          left: 0vw;
          width: 11vw;
        }
        &.n_03 {
          top: 44vh;
          left: 13vw;
          width: 9vw;
        }
        &.n_04 {
          top: 73vh;
          left: 17vw;
          width: 11vw;
        }
        &.n_05 {
          top: 97vh;
          left: 18vw;
          width: 10vw;
        }
        &.n_06 {
          top: -10vh;
          left: 30vw;
          width: 9vw;
        }
        &.n_07 {
          top: 34vh;
          left: 29vw;
          width: 15vw;
        }
        &.n_08 {
          top: 114vh;
          left: 7vw;
          width: 5vw;
        }
        &.n_09 {
          top: -12vh;
          left: 66vw;
          width: 12vw;
        }
        &.n_10 {
          top: 20vh;
          left: 54vw;
          width: 11vw;
        }
        &.n_11 {
          top: 62vh;
          left: 52vw;
          width: 10vw;
        }
        &.n_12 {
          top: 116vh;
          left: 61vw;
          width: 14vw;
        }
        &.n_13 {
          top: 75vh;
          left: 77vw;
          width: 9vw;
        }
        &.n_14 {
          top: 50vh;
          left: 94vw;
          width: 11vw;
        }
        &.n_15 {
          top: 104vh;
          left: 93vw;
          width: 10vw;
        }
        @include c.sp {
          &.n_01 {
            top: 25vh;
            left: -3vw;
            width: 19vw;
          }
          &.n_02 {
            top: 75vh;
            left: 0vw;
            width: 17vw;
          }
          &.n_03 {
            top: 7vh;
            left: 17vw;
            width: 10vw;
          }
          &.n_04 {
            top: 38vh;
            left: 17vw;
            width: 19vw;
          }
          &.n_05 {
            top: 87vh;
            left: 18vw;
            width: 16vw;
          }
          &.n_06 {
            display: none;
          }
          &.n_07 {
            top: 24vh;
            left: 29vw;
            width: 21vw;
          }
          &.n_08 {
            top: 44vh;
            left: 32vw;
            width: 11vw;
          }
          &.n_09 {
            top: 7vh;
            left: 53vw;
            width: 18vw;
          }
          &.n_10 {
            top: 30vh;
            left: 70vw;
            width: 17vw;
          }
          &.n_11 {
            top: 50vh;
            left: 56vw;
            width: 16vw;
          }
          &.n_12 {
            top: 116vh;
            left: 61vw;
            width: 20vw;
          }
          &.n_13 {
            top: 61vh;
            left: 75vw;
            width: 15vw;
          }
          &.n_14 {
            top: 50vh;
            left: 88vw;
            width: 17vw;
          }
          &.n_15 {
            top: 66vh;
            left: 40vw;
            width: 16vw;
          }
        }
      }
    }
    // jsでstyleを直指定して使用中
    @keyframes paper_animation {
      0% {
        transform: translate3d(0, 0, 0);
      }
      50% {
        transform: translate3d(0, clamp(60px, 10vw, 120px), 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
    #js__character_bg_props,
    #js__copy_r,
    #js__copy_l {
      opacity: 1;
      transition: 1s;
      &.disabled {
        opacity: 0;
      }
    }
    .copy_r,
    .copy_l {
      display: flex;
      flex-direction: row-reverse;
      gap: clamp(10px, 3vw, 40px);
      align-items: flex-start;
      position: fixed;
      top: calc(v.$header_height + 0px);
      overflow: hidden;
      @include c.tab {
        top: calc(v.$header_height_sp + 4vh);
      }
      @include c.sp {
        top: calc(v.$header_height_sp + 3vh);
      }
    }
    .copy_r {
      right: min(10vw, 180px);
      @include c.tab {
        right: min(8vw, 100px);
      }
      @include c.sp {
        right: min(6vw, 30px);
      }
    }
    .copy_l {
      left: min(10vw, 180px);
      @include c.tab {
        left: min(8vw, 100px);
      }
      @include c.sp {
        left: min(6vw, 30px);
      }
    }
    .copy_rr,
    .copy_rl,
    .copy_lr,
    .copy_ll {
      position: relative;
      img {
        opacity: 0;
        width: clamp(20px, 4.6vh, 44px);
        height: auto;
        @include c.low_height {
          width: clamp(18px, 4vh, 44px);
        }
        @include c.tab {
          width: clamp(18px, 4vh, 44px);
        }
        @include c.sp {
          width: clamp(20px, 2.5vh, 30px);
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: v.$letter_primary;
        transform-origin: top;
        transform: scaleY(0);
      }
      &.active {
        &:after {
          animation: copy_text_revealer 0.6s ease 1;
          animation-fill-mode: forwards;
        }
        img {
          animation: copy_text_appear 0.3s steps(1) 1;
          animation-fill-mode: forwards;
          @include c.anim_delay;
        }
      }
      @keyframes copy_text_revealer {
        0% {
          transform-origin: top;
          transform: scaleY(0);
        }
        50% {
          transform-origin: top;
          transform: scaleY(1);
        }
        50.001% {
          transform-origin: bottom;
        }
        100% {
          transform-origin: bottom;
          transform: scaleY(0);
        }
      }
      @keyframes copy_text_appear {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .copy_rr.active {
      &:after {
        animation-delay: 0.3s;
      }
      img {
        animation-delay: 0.3s;
      }
    }
    .copy_rl.active {
      &:after {
        animation-delay: 0.4s;
      }
      img {
        animation-delay: 0.4s;
      }
    }
    .copy_lr.active {
      &:after {
        animation-delay: .7s;
      }
      img {
        animation-delay: .7s;
      }
    }
    .copy_ll.active {
      &:after {
        animation-delay: .8s;
      }
      img {
        animation-delay: .8s;
      }
    }
  }
  #concept {
    background-color: rgba(255, 255, 255, 0.88);
    transition: .6s;
    padding: 184px 154px 180px;
    opacity: 0;
    position: relative;
    @include c.tab {
      padding: 146px 80px 160px;
    }
    @include c.sp {
      padding: 146px v.$body_pd_sp 120px;
    }
    &.active {
      opacity: 1;
    }
    .concept_bg_paper {
      position: absolute;
      top: 150vh;
      left: 0;
      transform: translate(0, 0);
      width: 100%;
      transition: 7s cubic-bezier(0.075, 0.82, 0.165, 1);
      img {
        width: 100%;
        height: auto;
        opacity: 0.7;
      }
      @include c.tab {
        display: none;
      }
    }
    .head {
      img {
        display: block;
        margin: 0 auto;
        width: clamp(116px, f.clamp_prf(184px), 184px);
        height: auto;
      }
    }
    .copy {
      margin-top: 60px;
      font-size: clamp(24px, 4vw, 75px);
      line-height: 138%;
      text-align: center;
      letter-spacing: 0.05em;
      @include c.gradation_text(v.$ddt_grad);
      @include c.tab {
        margin-top: 30px;
      }
    }
    .concept_background {
      position: relative;
      overflow: hidden;
      width: 100%;
    }
    .questions {
      position: relative;
      z-index: 1;
      font-family: v.$noto;
      margin-top: 206px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      .text {
        text-align: center;
        font-weight: v.$fw_medium_500;
        font-size: clamp(20px, 2vw, 28px);
        line-height: 228%;
        letter-spacing: 0.185em;
        .space {
          display: none;
        }
      }
      @include c.tab {
        margin-top: 100px;
        gap: 32px;
        .text {
          text-align: left;
          font-size: 20px;
          .space {
            display: inline;
          }
        }
      }
      @include c.sp {
        .text {
          font-size: 16px;
        }
      }
    }
    .story {
      position: relative;
      z-index: 1;
      margin-top: 206px;
      text-align: center;
      font-weight: v.$fw_medium_500;
      font-size: clamp(13px, f.clamp_prf(18px), 18px);
      line-height: 292%;
      letter-spacing: 0.115em;
      @include c.tab {
        margin-top: 80px;
      }
    }
    .support_range {
      position: relative;
      z-index: 1;
      margin: 70px 0 0;
      text-align: center;
      font-weight: v.$fw_medium_500;
      font-size: clamp(17px, f.clamp_prf(31px), 31px);
      line-height: 216%;
      letter-spacing: 0.105em;
      @include c.tab {
        margin: 68px 0 0;
      }
    }
  }
  #anxiety {
    height: 120vh;
    .speechbubble_wrapper {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      transition: 0.8s cubic-bezier(0.1, 0.69, 0, 1);
      position: fixed;
      width: clamp(200px, 30vw, 570px);
      height: auto;
      transform: scale(0);
      $speechbubble_x: 10vw;
      $speechbubble_y_offset: 22vh;
      $speechbubble_y_gap: 16vh;
      &.lt {
        top: $speechbubble_y_offset;
        left: $speechbubble_x;
        transform-origin: right top;
      }
      &.rt {
        top: $speechbubble_y_offset + $speechbubble_y_gap;
        right: $speechbubble_x;
        transform-origin: left top;
        transition-delay: 0.1s;
      }
      &.lb {
        top: $speechbubble_y_offset + ($speechbubble_y_gap * 2);
        left: $speechbubble_x;
        transform-origin: right top;
        transition-delay: 0.2s;
      }
      &.rb {
        top: $speechbubble_y_offset + ($speechbubble_y_gap * 3);
        right: $speechbubble_x;
        transform-origin: left top;
        transition-delay: 0.3s;
      }
    }
    &.active {
      .speechbubble_wrapper {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }
    .speechbubble {
      position: relative;
      width: 100%;
      height: 100%;
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-52%, -50%);
        font-weight: 500;
        font-size: 22px;
        line-height: 164.8%;
        letter-spacing: 0.12em;
        width: 82%;
        text-align: justify;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
    @include c.tab {
      position: relative;
      height: 100vh;
      .speechbubble_wrapper {
        position: absolute;
        width: clamp(200px, 80vw, 400px);
        $speechbubble_x_sp: 2vw;
        $speechbubble_y_offset_sp: 10vh;
        $speechbubble_y_gap_sp: 20vh;
        &.lt {
          top: $speechbubble_y_offset_sp;
          left: $speechbubble_x_sp;
          transform-origin: right top;
        }
        &.rt {
          top: $speechbubble_y_offset_sp + $speechbubble_y_gap_sp;
          right: $speechbubble_x_sp;
          transform-origin: left top;
          transition-delay: 0.1s;
        }
        &.lb {
          top: $speechbubble_y_offset_sp + ($speechbubble_y_gap_sp * 2);
          left: $speechbubble_x_sp;
          transform-origin: right top;
          transition-delay: 0.2s;
        }
        &.rb {
          top: $speechbubble_y_offset_sp + ($speechbubble_y_gap_sp * 3);
          right: $speechbubble_x_sp;
          transform-origin: left top;
          transition-delay: 0.3s;
        }
      }
    }
  }

  #contractor_num {
    position: relative;
    z-index: 2;
    margin: 0;
    background-color: #fff;
    padding: 120px 40px 0;
    transition: 0.3s ease;
    .contractor_num_text {
      font-size: 30px;
      line-height: 155%;
      letter-spacing: 0.1em;
      text-align: center;
      @include c.gradation_text(v.$ddt_grad);
      width: auto;
      position: relative;
    }
    .em {
      font-weight: v.$fw_bold_700;
      font-size: 123%;
      &::selection {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #fff;
        background-clip: text;
        text-fill-color: #fff;
      }
    }
    @include c.tab {
      padding: 80px 0 0;
      .contractor_num_text {
        font-size: 18px;
        line-height: 159%;
      }
    }
  }

  #merits {
    background-color: #fff;
    padding-bottom: 220px;
    position: relative;
    z-index: 1;
    @include c.tab {
      padding-bottom: 140px;
    }
    .c__head_primary {
      margin: 160px 75px 0;
      @include c.tab {
        margin: 120px 0 0 0;
      }
      @include c.sp {
        margin: 120px 0 0 calc(-1 * v.$body_pd_sp);
      }
    }
    .contents_wrapper {
      @include c.content_width;
      $merits_contents_wrapper_padding: clamp(v.$body_pd, calc(120px - 5vw), 75px); // 画面幅が狭い時は75px、広い時はコンテンツ幅に合わせる
      padding-left: $merits_contents_wrapper_padding;
      padding-right: $merits_contents_wrapper_padding;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 36px;
      @include c.tab {
        margin-top: 60px;
        gap: clamp(60px, 16vw, 120px);
      }
    }
    .description_wrapper {
      display: flex;
      opacity: 0;
      transition: all .3s ease;
      .text_wrapper {
        flex: 1;
        .head {
          font-size: clamp(24px, 2.6vw, 40px);
          font-weight: v.$fw_medium_500;
          line-height: 156.8%;
          letter-spacing: 0.12em;
        }
        .description {
          margin: clamp(24px, 3vw, 40px) 0 0;
          text-align: justify;
          font-size: clamp(12px, 1.4vw, 16px);
          line-height: 212%;
          letter-spacing: 0.185em;
        }
      }
      .image_wrapper {
        flex: 1;
        img {
          width: 100%;
          height: auto;
        }
      }
      &:nth-child(odd) {
        flex-direction: row;
        transform: translateX(-30px);
        .text_wrapper {
          margin: 90px clamp(34px, 3vw, 54px) 0 0;
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        transform: translateX(30px);
        .text_wrapper {
          margin: 90px 0 0 clamp(34px, 3vw, 54px);
        }
      }
      &.active {
        transform: translateX(0) scale(100%);
        opacity: 1;
      }
      @include c.tab {
        flex-direction: column;
        .text_wrapper {
          margin: 0;
          .head {
            font-size: clamp(25px, 5vw, 40px);
          }
          .description {
            margin: clamp(20px, 5vw, 40px) 0 0;
            font-size: clamp(13px, 3vw, 16px);
          }
        }
        .image_wrapper {
          margin-top: clamp(20px, f.clamp_prf(40px), 40px);
        }
        &:nth-child(odd) {
          flex-direction: column;
          .text_wrapper {
            margin: 0;
          }
        }
        &:nth-child(even) {
          flex-direction: column;
          .text_wrapper {
            margin: 0;
          }
        }
      }
    }
  }
  #plans {
    background-color: v.$gray;
    padding: 160px 40px 220px;
    @include c.tab {
      padding: 90px 0 120px;
    }
    .c__head_primary {
      @include c.tab {
        margin: 0;
        padding: 0;
      }
    }
    .pricing_description {
      margin-top: 160px;
      font-weight: v.$fw_medium_500;
      font-size: 24px;
      line-height: 177.3%;
      text-align: center;
      letter-spacing: 0.12em;
      @include c.tab {
        margin-top: 74px;
        font-weight: v.$fw_normal_400;
        font-size: clamp(12px, 3vw, 24px);
      }
    }
    .plan_lists {
      display: flex;
      gap: 8px;
      margin-top: 100px;
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 86px;
      .plan {
        position: relative;
        .dots {
          width: 4px;
          height: 28px;
          position: absolute;
          bottom: -68px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .plan_head {
        border-radius: 8px 8px 0px 0px;
        width: 264px;
        height: 72px;
        display: grid;
        place-items: center;
        margin: 0 auto;
        color: #fff;
        font-weight: v.$fw_bold_700;
        font-size: 22px;
        letter-spacing: 0.08em;
      }
      .plan_content {
        width: 264px;
        border-radius: 0px 0px 8px 8px;
      }
      .plan_details {
        padding: 12px 12px 0 12px;
      }
      .plan_image {
        width: 100%;
        height: auto;
      }
      .target {
        padding: 36px 20px 20px;
        height: 138px;
      }
      .target_head {
        width: 120px;
        height: 22px;
        color: #fff;
        font-weight: v.$fw_medium_500;
        font-size: 12px;
        border-radius: 3px;
        display: grid;
        place-items: center;
        margin: 0 auto;
        padding-bottom: 2px;
      }
      .target_desctiption {
        margin-top: 12px;
        font-size: 14px;
        line-height: 156.8%;
        text-align: center;
        letter-spacing: 0.12em;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .price {
        padding: 26px 20px 20px;
        border-top: 1px solid #e2e2e2;
        font-weight: v.$fw_medium_500;
        text-align: center;
        letter-spacing: 0.12em;
        min-height: 100px;
        .monthly {
          font-size: 19px;
        }
        .annually {
          margin-top: 10px;
          font-size: 14px;
        }
      }
      .green {
        .plan_head {
          background: linear-gradient(90deg, #02aab0 0%, #00cdac 100%);
        }
        .plan_content {
          background-color: #f4fff7;
        }
        .plan_description {
          color: #4f9663;
        }
        .target_head {
          background: #4f9663;
        }
        .target_desctiption {
          color: #4f9663;
        }
        .price {
          color: #4f9663;
        }
      }
      .blue {
        .plan_head {
          background: linear-gradient(90deg, #529aff 0%, #5ecaf9 100%);
        }
        .plan_content {
          background-color: #f1f8ff;
        }
        .plan_description {
          color: #275a88;
        }
        .target_head {
          background: #275a88;
        }
        .target_desctiption {
          color: #275a88;
        }
        .price {
          color: #275a88;
        }
      }
      .red {
        .plan_head {
          background: linear-gradient(90deg, #f2709c 0%, #ff7272 100%);
        }
        .plan_content {
          background-color: #fff6f4;
        }
        .plan_description {
          color: #d05644;
        }
        .target_head {
          background: #d05644;
        }
        .target_desctiption {
          color: #d05644;
        }
        .price {
          color: #d05644;
        }
      }
      .yellow {
        .plan_head {
          background: linear-gradient(90deg, #ffbf43 0%, #fccd3d 100%);
        }
        .plan_content {
          background-color: #fffbf8;
        }
        .plan_description {
          color: #aa8421;
        }
        .target_head {
          background: #aa8421;
        }
        .target_desctiption {
          color: #aa8421;
        }
        .price {
          color: #aa8421;
        }
      }
    }
    .service {
      .head {
        background-color: v.$darkgray;
        width: 100%;
        height: 48px;
        display: grid;
        place-items: center;
        margin: 0 auto;
        border-radius: 8px 8px 0px 0px;
        font-weight: v.$fw_medium_500;
        font-size: 20px;
        letter-spacing: 0.24em;
        color: #4b4b4b;
        @include c.sp {
          height: 32px;
        }
      }
      .contents_wrapper {
        width: 100%;
        background-color: #fff;
        border-radius: 0px 0px 8px 8px;
      }
      .contents {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 14px 34px;
        max-width: 700px;
        margin: 0 auto;
        padding: 32px 24px 28px;
        font-size: 16px;
        letter-spacing: 0.12em;
        @include c.sp {
          flex-direction: column;
          align-items: center;
          font-size: clamp(14px, 2vw, 16px);
        }
      }
      .contents_annotation {
        padding: 0 24px 24px;
        text-align: center;
        font-size: 14px;
        line-height: 206.3%;
        letter-spacing: 0.12em;
        @include c.tab {
          font-size: clamp(12px, 2vw, 14px);
        }
      }
    }
    .annotation {
      margin-top: 22px;
      font-size: 14px;
      line-height: 176.3%;
      text-align: center;
      letter-spacing: 0.12em;
      color: #747474;
      @include c.sp {
        font-size: 11px;
      }
    }
    .contact {
      margin-top: 120px;
      @include c.tab {
        margin-top: 74px;
      }
      .prompt {
        font-weight: v.$fw_medium_500;
        font-size: 24px;
        text-align: center;
        letter-spacing: 0.12em;
        line-height: 154.3%;
        @include c.tab {
          font-size: 20px;
        }
      }
      .cv_wrapper {
        margin-top: 24px;
        display: flex;
        @include c.tab {
          flex-direction: column;
          align-items: center;
          height: auto;
        }
        .cv_speechbubble {
          flex: 1;
          margin-right: 20px;
          @include c.tab {
            margin: 0 auto;
            width: 100%;
          }
        }
        .speechbubble_pc {
          width: 100%;
          height: auto;
          @include c.tab {
            display: none;
          }
        }
        .speechbubble_sp {
          display: none;
          margin: 0 auto 15px;
          max-width: 400px;
          width: 100%;
          height: auto;
          @include c.tab {
            display: block;
          }
        }
        .cv_link {
          flex: 1;
          font-size: clamp(16px, 2.5vw, 32px);
          display: flex;
          gap: clamp(12px, f.clamp_prf(25px), 25px);
          justify-content: center;
          align-items: center;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          background: linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #833ab4 100%);
          background-size: 200% 100%;
          background-position: 0% 0%;
          transition: 1s ease;
          @include c.tab {
            width: 100%;
            max-width: clamp(328px, f.clamp_prf(500px), 500px);
          }
          @include c.hover_device {
            &:hover {
              background-position: -100% 0%;
            }
          }
          .icon {
            display: block;
            width: clamp(20px, f.clamp_prf(40px), 40px);
            @include c.tab {
              height: clamp(60px, 13vw, 90px);
            }
          }
          .text {
            font-weight: v.$fw_medium_500;
            letter-spacing: 0.12em;
            color: #ffffff;
          }
        }
      }
    }
    .contact.disabled {
      .prompt {
        font-size: clamp(13px, 3.8vw, 24px);
      }
      .cv_wrapper {
        justify-content: center;
        .cv_link {
          transition: none;
          height: 122px;
          max-width: 540px;
          box-shadow: none;
          background: #bcbcbc;
          @include c.tab {
            width: 100%;
            max-width: clamp(328px, f.clamp_prf(500px), 500px);
            .text {
              height: clamp(60px, 13vw, 90px);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          @include c.hover_device {
            &:hover {
              background-position: 0% 0%;
            }
          }
        }
      }
    }
  }
  #offices {
    background-color: #fff;
    padding: 120px 40px 220px;
    @include c.tab {
      padding: 120px 0 170px;
    }
    .c__head_primary {
      @include c.tab {
        margin: 0 0 0 0;
        padding: 0;
        width: calc(100% + v.$body_pd_sp + v.$body_pd_sp);
      }
    }
    .offices_head {
      font-size: 28px;
      line-height: 200%;
      text-align: center;
      letter-spacing: 0.115em;
    }
    .offices_head.management {
      margin-top: 120px;
      @include c.tab {
        margin-top: 100px;
      }
    }
    .manage_office_logo {
      margin: 0 auto;
      text-align: center;
      img {
        width: 100%;
        max-width: 320px;
        height: auto;
      }
      @include c.tab {
        margin-top: 20px;
      }
    }
    .offices_head.partners {
      margin-top: 86px;
    }
    .offices_annotarion {
      margin-top: 6px;
      font-size: 14px;
      line-height: 164%;
      text-align: center;
      letter-spacing: 0.115em;
      @include c.tab {
        @include c.content_width;
        margin-top: 20px;
      }
    }
    .partner_offices_list {
      @include c.content_width;
      margin-top: 72px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px 16px;
      li {
        max-width: clamp(122px, 38vw, 258px);
        img {
          width: 100%;
          height: auto;
        }
      }
      @include c.tab {
        margin-top: 26px;
        gap: 8px 24px;
      }
    }
  }
  #profile {
    background-color: v.$gray;
    padding: 140px 40px 370px;
    @include c.tab {
      padding: 130px 0 calc(v.$footer_height + 100px);
    }
    .c__head_primary {
      @include c.tab {
        margin: 0;
        padding: 0;
      }
    }
    .profile_wrapper {
      @include c.content_width;
      margin-top: 80px;
      display: flex;
      gap: 60px;
      align-items: flex-start;
      @include c.sp {
        flex-direction: column;
      }
    }
    .logo {
      width: 170px;
      height: auto;
      @include c.sp {
        width: 88px;
      }
    }
    .profile_head {
      font-weight: v.$fw_bold_700;
      font-size: 16px;
      letter-spacing: 0.115em;
    }
    .address {
      margin-top: 12px;
      font-size: 14px;
      line-height: 164%;
      letter-spacing: 0.185em;
    }
    .profile_annotation {
      margin-top: 15px;
      font-size: 11px;
      line-height: 168%;
      letter-spacing: 0.155em;
      @include c.sp {
        margin-top: 30px;
      }
    }
    .copyright {
      margin-top: 108px;
      text-align: center;
      img {
        width: 126px;
        height: auto;
      }
      .text {
        margin-top: 8px;
        font-size: 10px;
      }
    }
  }
}

#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: v.$footer_height;
  transition: 0.2s ease;
  background: v.$ddt_grad;
  z-index: 8;
  @include c.hover_device {
    &:hover {
      height: 70px;
    }
  }
  .contact_link {
    cursor: pointer;
    display: flex;
    gap: 26px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  .contact_link_icon {
    width: 21px;
  }
  .contact_link_text {
    font-size: 18px;
    letter-spacing: 0.12;
  }
  @include c.sp {
    height: v.$footer_height_sp;
    .contact_link {
      gap: 16px;
    }
    .contact_link_text {
      font-size: clamp(14px, 1.8vh, 18px);
    }
  }
}
#pagetop_button {
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 9;
  width: clamp(46px, f.clamp_prf(80px), 80px);
  height: auto;
  opacity: 0;
  transition: 0.2s ease;
  &.active {
    bottom: calc(v.$footer_height + 22px);
    opacity: 1;
    @include c.tab {
      bottom: calc(v.$footer_height_sp + 22px);
    }
  }
  .img_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: 0.2s ease;
  }
  .letter {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
    width: 28%;
    height: auto;
  }
  @include c.hover_device {
    &:hover {
      .arrow {
        bottom: 16px;
      }
    }
  }
}
