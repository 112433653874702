// 色系
$letter_primary: #393939;
$ddt_grad: linear-gradient(90deg, #8A2387 0%, #E94057 50%, #F27121 100%);
$gray: #ececec;
$darkgray: #C6C6C6;
$selection_bg: $letter_primary;
$selection_text: #fff;
$tw_blue: #1da1f2;

// フォント系
$noto_yakuhan: "YakuHanJP", "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$noto: "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$poppins: 'Poppins', "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //extralight: 200 semibold: 600
$fw_exlight_200: 200;
$fw_light_300: 300;
$fw_normal_400: 400;
$fw_medium_500: 500;
$fw_semibold_600: 600;
$fw_bold_700: 700;

//ヘッダーフッターの高さ
$header_height: 160px;
$header_height_sp: 74px;
$footer_height: 48px;
$footer_height_sp: max(7vh, 52px);

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$pc_min: 1080px; // pcコンテンツ幅
$tab_max: 920px;
$sp_max: 712px;
$layout_min: 320px;
$body_pd: 35px;
$body_pd_sp: 24px;

// vw変換用(デザインデータキャンバス幅pxに合わせる)
$vp_basis_pc: 1920;
$vp_basis_sp: 375;

/*=======================================
  z-index管理
=========================================

---- 99999 ----


---- 1000 -----


---- 900 -----


----- 20 -----
#header_toggle


----- 15 -----
#sp_menu

----- 12 -----


----- 11 -----
.header_l

----- 10 -----
#header
#sp_menu

----- 9 -----
#pagetop_button

----- 8 -----
#footer

----- 7 -----


------ 5 ------
.p__home .home_first_view .fv_head
#footer .copyright

------ 2 ------
#contractor_num

------ 1 ------
#concept.questions
#concept.story
#concept.support_range
#merits

------ -1 ------
#first_view


------ -10 -----


------ -50 -----

------ -100 ----


------ -1000 ----


*/