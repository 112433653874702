@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/common" as c;

/*==============================
文字種
==============================*/
.c__common_text {
  font-size: clamp(12px, 2vw, 14px);
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(14, 26);
  text-align: justify;
  @include c.sp {
    line-height: f.xd_line_height(12, 24);
  }
}
.c__head_description {
  font-size: clamp(12px, 2vw, 14px);
  font-weight: v.$fw_medium_500;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(40);
  line-height: f.xd_line_height(16, 32);
  text-align: center;
  @include c.sp {
    line-height: f.xd_line_height(14, 28);
  }
}

.c__text_underline {
  position: relative;
  width: 100%;
  padding-bottom: 0.15em;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }
}

// 改行しても背景がぶつ切りにならないやつ
.c__box_decoration_break_clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  box-shadow: 8px 8px 10px 0 #ff1492, -5px -5px 5px 0 #00f, 5px 5px 15px 0 #ff0;
}

/*==============================
見出し系
==============================*/
.c__head_primary {
  @include c.content_width(1620px);
  padding: 0;
  margin: 0 v.$body_pd;
  font-size: clamp(58px, 14vw, 164px);
  font-weight: v.$fw_bold_700;
  font-family: v.$poppins;
  color: transparent;
  letter-spacing: 0.21em;
  display: inline-block;
  width: auto;
  position: relative;
  @include c.sp {
    font-size: clamp(58px, 17vw, 164px);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: v.$letter_primary;
    transform-origin: right;
    transform: scaleX(0);
  }
  &.active {
    animation: text_appear 0.3s steps(1) 1;
    animation-fill-mode: forwards;
    &:after {
      animation: text_revealer .6s ease 1;
      animation-fill-mode: forwards;
    }
  }
  @keyframes text_revealer {
    0% {
      transform-origin: right;
      transform: scaleX(0);
    }
    50% {
      transform-origin: right;
      transform: scaleX(1);
    }
    50.001% {
      transform-origin: left;
    }
    100% {
      transform-origin: left;
      transform: scaleX(0);
    }
  }
  @keyframes text_appear {
    0% {
      color: transparent;
    }
    100% {
      color: v.$letter_primary;
    }
  }
}

/*==============================
ボタン系
==============================*/

.c__button_underline {
  width: 100%;
  font-family: v.$poppins;
  font-weight: v.$fw_exlight_200;
  font-size: 20px;
  text-align: center;
  a {
    position: relative;
    width: 98px;
    padding-bottom: 10px;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: v.$letter_primary;
    }
  }
  @include c.tab {
    margin-bottom: 100px;
  }
}
.c__simple_table {
  .tr {
    display: flex;
  }
}
/*==============================
  装飾系
==============================*/

.c__external_link {
  @include c.external_link_icon;
}

.c__js_highlights {
  // 以下のように使用
  // <div class="c__js_highlight"> #IntersectionObserverで監視
  //   <p class="highlight delay1">ハイライトテキスト</p>
  // </div>
  $highlight_color: #ff9473;
  $highlight_thikness: 100%;
  .c__highlight {
    display: inline;
    position: relative;
    background-image: linear-gradient(90deg, $highlight_color, $highlight_color);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 0 $highlight_thikness;
  }
  &.active {
    .c__highlight {
      transition: all 0.4s ease; //マーカーの速度
      background-size: 100% $highlight_thikness;
      @include c.tran_delay(0.2);
    }
  }
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  // will-change: transform;
  transform: translate(0, 40px);
  opacity: 0;
  transition: .3s ease;
  &.active {
    transform: translate(0,0) scale(100%);
    opacity: 1;
  }
  @include c.tran_delay(0.1);
}
.c__js_blur {
  .c__js_blur_child {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    filter: blur(10px);
  }
  &.active {
    .c__js_blur_child {
      opacity: 1;
      filter: blur(0px);
      @include c.tran_delay(0.2);
    }
  }
}
.js__transition {
  .js__transition_child {
    // .js__transition_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include c.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    // .js__animation_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include c.anim_delay(0.2);
  }
}
